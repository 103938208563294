import { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { useTerminalStore } from './store'
import { CommandProcessor } from './CommandProcessor'

// 커서 깜박임 애니메이션
const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`

// 텍스트 페이드인 애니메이션
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(1px); }
  to { opacity: 1; transform: translateY(0); }
`

// 터미널 전체 컨테이너
const TerminalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 400px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  border: 1px solid #333;
  font-size: 14px;
  line-height: 1.5;
  opacity: 0.97;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
  }
`

// 터미널 타이틀 바
const TerminalTitleBar = styled.div`
  background: linear-gradient(to bottom, #4d4d4d, #2d2d2d);
  color: #e0e0e0;
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, sans-serif;
  user-select: none;
  border-bottom: 1px solid #1a1a1a;
`

// 터미널 타이틀 좌측 부분
const TerminalTitle = styled.div`
  display: flex;
  align-items: center;
`

// 원형 버튼 (접기/최소화/닫기)
const TerminalButton = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 8px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2) inset;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(1.1);
  }
`

// 터미널 내용 컨테이너
const TerminalContainer = styled.div`
  background-color: rgba(30, 30, 30, 0.95);
  color: #f0f0f0;
  padding: 1rem;
  font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  border-radius: 0;
  position: relative;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(100, 100, 100, 0.4);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(100, 100, 100, 0.7);
  }
`

const InputWrapper = styled.div`
  position: relative;
  width: 80%;
  display: flex;
  align-items: center;
`

const Cursor = styled.span`
  display: inline-block;
  width: 8px;
  height: 16px;
  background-color: #f0f0f0;
  animation: ${blink} 1.2s step-end infinite;
  position: absolute;
  left: 0;
`

const Input = styled.input`
  background-color: transparent;
  border: none;
  color: #f0f0f0;
  font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
  width: 100%;
  outline: none;
  font-size: inherit;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  caret-color: transparent;
`

const PromptLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

const UserInfo = styled.span`
  color: #56c6f0; // 밝은 파란색
  margin-right: 4px;
  font-weight: bold;
`

const HostInfo = styled.span`
  color: #e683d9; // 보라색
  margin-right: 4px;
  font-weight: bold;
`

const PathInfo = styled.span`
  color: #6aded9; // 청록색
  margin-right: 4px;
`

const Prompt = styled.span`
  color: #60e950; // 밝은 녹색
  margin: 0 6px;
  font-weight: bold;
`

const OutputLine = styled.div`
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
  opacity: 0.95;
  animation: ${fadeIn} 0.2s ease-out;
`

export function Terminal() {
  const inputRef = useRef<HTMLInputElement>(null)
  const terminalRef = useRef<HTMLDivElement>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const { history, output, addToHistory, addToOutput } = useTerminalStore()
  const [currentCommand, setCurrentCommand] = useState<string | null>(null)
  const [historyIndex, setHistoryIndex] = useState<number>(-1)
  const [cursorPosition, setCursorPosition] = useState<number>(0)

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight
    }
  }, [output])

  useEffect(() => {
    // 초기 터미널 메시지 출력
    addToOutput('환영합니다! 터미널에 접속했습니다.')
    addToOutput('명령어 목록을 보려면 "help"를 입력하세요.')
  }, [addToOutput])

  // 입력 값이 바뀔 때마다 커서 위치 업데이트
  useEffect(() => {
    setCursorPosition(inputValue.length * 8) // 대략적인 글자 너비 계산
  }, [inputValue])

  const handleKeyDown = async (e: React.KeyboardEvent) => {
    // 위 화살표 키 처리
    if (e.key === 'ArrowUp') {
      e.preventDefault()

      if (history.length > 0 && historyIndex < history.length - 1) {
        const newIndex = historyIndex + 1
        setHistoryIndex(newIndex)
        setInputValue(history[history.length - 1 - newIndex])
      }
    }

    // 아래 화살표 키 처리
    else if (e.key === 'ArrowDown') {
      e.preventDefault()

      if (historyIndex > 0) {
        const newIndex = historyIndex - 1
        setHistoryIndex(newIndex)
        setInputValue(history[history.length - 1 - newIndex])
      } else if (historyIndex === 0) {
        setHistoryIndex(-1)
        setInputValue('')
      }
    }

    // 엔터 키 처리
    else if (e.key === 'Enter' && inputValue.trim()) {
      const command = inputValue.trim()

      // 커맨드를 히스토리에 추가
      addToHistory(command)

      // 입력값과 프롬프트를 출력에 추가
      addToOutput(`chunsik@blog:~$ ${command}`)

      // CommandProcessor에 명령어 전달
      setCurrentCommand(command)

      // 입력값 초기화 및 히스토리 인덱스 리셋
      setInputValue('')
      setHistoryIndex(-1)
    }
  }

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <TerminalWrapper>
      <TerminalTitleBar>
        <TerminalTitle>
          <TerminalButton color='#FF5F57' />
          <TerminalButton color='#FFBD2E' />
          <TerminalButton color='#28CA41' />
          <span style={{ marginLeft: '10px', fontSize: '13px' }}>KimChunsick@blog: ~/blog — zsh</span>
        </TerminalTitle>
        <span style={{ fontSize: '12px', opacity: 0.8 }}>bash</span>
      </TerminalTitleBar>
      <TerminalContainer ref={terminalRef} onClick={focusInput}>
        {output.map((line: string, index: number) => (
          <OutputLine key={index}>{line}</OutputLine>
        ))}
        <PromptLine>
          <UserInfo>chunsik</UserInfo>
          <span>@</span>
          <HostInfo>blog</HostInfo>
          <span>:</span>
          <PathInfo>~</PathInfo>
          <Prompt>$</Prompt>
          <InputWrapper>
            <Input
              ref={inputRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus
            />
            <Cursor style={{ left: `${cursorPosition}px` }} />
          </InputWrapper>
        </PromptLine>
        {currentCommand && <CommandProcessor command={currentCommand} />}
      </TerminalContainer>
    </TerminalWrapper>
  )
}
